$pt-link-color: #0ac2cc;
$pt-intent-primary: #0ac2cc;
$pt-text-color: #adbbc4;

@import '~normalize.css';
@import '~@blueprintjs/core/src/blueprint.scss';

* {
  font-family: 'Roboto';
}

body {
  background-color: white;
  color: #222f3c;
  overflow-y: scroll;
}

a {
  color: inherit;
}

.modify-tabs {
  .bp3-tab-list {
    border-bottom: 1px solid #d4dade;
    *:not(:last-child) {
      margin-right: 0px;
    }
    overflow-x: auto;
    overflow-y: hidden;
  }
  .bp3-tab-panel {
    margin-top: 0px;
    padding: 16px;
    @media (min-width: 768px) {
      margin-top: 16px;
      padding: 0px;
    }
  }
}

.bp3-dialog {
  background-color: white;
}
.react-tel-input .rovo-phone-input {
  border: 1px solid #c3d1de;
  height: 48px;
  color: #222f3c;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  :focus {
    border-color: #0ac2cc;
  }
}

.rovo-phone-input-button {
  .country-list .country:hover {
    background-color: #a6f2f2;
  }
}

.linkified {
  text-decoration: underline;
}

.linkified:hover {
  color: initial;
}
